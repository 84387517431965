<template>
  <div class="container">
    <div class="">
      <div class="row">
        <div class="col-12 p-0">
          <Folders
            @selectedFolderChanged="onSelectedFolderChanged"
            @selectedFolderDeleted="onSelectedFolderDeleted"
          />
          <div class="card theme-card mt-3">
            <div
              class="
                form-group
                row
                my-4
                mx-3
                align-items-center
                justify-content-between
              "
            >
              <div
                class="
                  col-12 col-md-9
                  d-flex
                  align-items-center
                  share-folder-wrapper
                "
              >
                <label
                  class="
                    assignee-label
                    pl-0
                    pr-4
                    col-form-label col-12 col-md-auto
                  "
                  for="users_id"
                  >Share selected folder with</label
                >
                <vue-tags-input
                  id="users_id"
                  name="users_id"
                  class="w-75"
                  v-model="folderTag"
                  :tags="folderTags"
                  :autocomplete-items="filteredItemsForFolders"
                  :add-only-from-autocomplete="true"
                  :autocomplete-min-length="0"
                  placeholder=""
                  @tags-changed="(newTags) => (folderTags = newTags)"
                />
              </div>
              <div class="col-auto">
                <save
                  :saving="folders.isSharing"
                  :disabled="!selectedFolderId || !folderTags.length"
                  @click="shareFolders"
                  classes="btn btn-block btn-black"
                >
                  Share
                </save>
              </div>

              <div
                class="
                  col-12
                  pl-3
                  mt-3
                  d-flex
                  justify-content-end
                  align-items-center
                  custom-checkbox custom-control-inline
                  direct-share-input
                "
              >
                <input
                  type="checkbox"
                  v-model="directFolderShare"
                  class="custom-control-input"
                  id="directFolder"
                />
                <label class="custom-control-label" for="directFolder"
                  >Share directly with tagged client(s)
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col p-0">
          <dropzone
            :options="dropzoneOptions"
            @success="uploadSuccess"
            v-if="
              selectedFolderId && (userInfo.isAdmin || userInfo.isManagingAdmin)
            "
          />
          <div class="card theme-card table-container">
            <alert v-if="documents.isLoading" class="m-4" />
            <alert
              v-if="!documents.isLoading && documents.data.length == 0"
              class="m-4"
              >No Documents Uploaded</alert
            >
            <table
              class="table table-striped mb-0 documents-details-table"
              v-if="!documents.isLoading && documents.data.length > 0"
            >
              <tr>
                <th>
                  <input
                    class="ml-2"
                    type="checkbox"
                    v-model="selectAll"
                    @click="setSelectAll"
                  />
                </th>
                <th>Icon</th>
                <th><span class="desktop-only">Name</span></th>
                <th class="desktop-only">Size</th>
                <th class="desktop-only"></th>
              </tr>
              <tr v-for="d in documents.data" :key="d.id">
                <td>
                  <input
                    class="ml-2"
                    type="checkbox"
                    v-model="selectedFileIds"
                    :value="d.id"
                  />
                </td>
                <td>
                  <icon
                    :type="`file-${getFileIcon(d)}`"
                    class="fa-2x text-muted m-0"
                  />
                </td>
                <td>
                  {{ d.original_file_name }}
                  <br />
                  <span class="text-right text-muted small">{{
                    prettyDate(d.created_at)
                  }}</span>
                  <br />
                  <badge v-for="f in d.folders" :key="f.id">{{
                    f.original_file_name
                  }}</badge>
                  <b class="mobile-tablet-only mr-2">Size:</b>
                  <span class="small text-muted mobile-tablet-only">{{
                    d.size
                  }}</span>
                  <div class="mobile-tablet-only">
                    <div class="mb-3 mt-2">
                      <button
                        v-if="canViewFile(d)"
                        @click="viewFile(d)"
                        class="btn btn-sm btn-theme mt-2"
                        :disabled="d.isViewing"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          v-if="d.isViewing"
                        ></span>
                        <span v-else>View</span>
                      </button>
                      <button
                        @click="downloadFile(d)"
                        class="btn btn-sm btn-black mt-2 ml-2"
                        :disabled="d.isDownloading"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          v-if="d.isDownloading"
                        ></span>
                        <span v-else>Download</span>
                      </button>
                      <button
                        v-if="userInfo.isAdmin || userInfo.isManagingAdmin"
                        @click="deleteDocumentModal(d)"
                        :disabled="d.isDeleting"
                        class="btn btn-sm btn-danger mt-2 ml-2"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          v-if="d.isDeleting"
                        ></span>
                        <span v-else>Delete</span>
                      </button>
                    </div>
                  </div>
                </td>
                <td class="desktop-only">
                  <span class="small text-muted">{{ d.size }}</span>
                </td>
                <td class="text-right desktop-only">
                  <button
                    v-if="canViewFile(d)"
                    @click="viewFile(d)"
                    class="btn btn-sm btn-theme mt-2"
                    :disabled="d.isViewing"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="d.isViewing"
                    ></span>
                    <span v-else>View</span>
                  </button>
                  <button
                    @click="downloadFile(d)"
                    class="btn btn-sm btn-black mt-2 ml-2"
                    :disabled="d.isDownloading"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="d.isDownloading"
                    ></span>
                    <span v-else>Download</span>
                  </button>
                  <button
                    v-if="userInfo.isAdmin || userInfo.isManagingAdmin"
                    @click="deleteDocumentModal(d)"
                    :disabled="d.isDeleting"
                    class="btn btn-sm btn-danger mt-2 ml-2"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="d.isDeleting"
                    ></span>
                    <span v-else>Delete</span>
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="card theme-card">
            <div
              class="
                form-group
                row
                my-4
                mx-3
                align-items-center
                justify-content-between
              "
            >
              <div
                class="
                  col-12 col-md-9
                  d-flex
                  align-items-center
                  share-document-wrapper
                "
              >
                <label
                  class="
                    assignee-label
                    pl-0
                    pr-4
                    col-form-label col-12 col-md-auto
                  "
                  for="user_id"
                  >Share selected document with</label
                >
                <vue-tags-input
                  id="user_id"
                  name="user_id"
                  class="w-75"
                  v-model="tag"
                  :tags="tags"
                  :autocomplete-items="filteredItems"
                  :add-only-from-autocomplete="true"
                  :autocomplete-min-length="0"
                  placeholder=""
                  @tags-changed="(newTags) => (tags = newTags)"
                />
              </div>
              <div class="col-auto">
                <save
                  :saving="folders.isSharing"
                  :disabled="!selectedFileIds.length || !tags.length"
                  @click="shareFiles"
                  classes="btn btn-block btn-black"
                >
                  Share
                </save>
              </div>
              <div
                class="
                  col-12
                  pl-3
                  mt-3
                  d-flex
                  justify-content-end
                  align-items-center
                  custom-checkbox custom-control-inline
                "
              >
                <input
                  type="checkbox"
                  v-model="directDocumentShare"
                  class="custom-control-input"
                  id="directDocument"
                />
                <label class="custom-control-label" for="directDocument"
                  >Share directly with tagged client(s)
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <file-viewer
      :name="selectedDoc.name"
      :type="selectedDoc.type"
      :link="selectedDoc.link"
    />
    <video-viewer
      :videoName="selectedDoc.name"
      :videoLink="selectedDoc.link"
      :noDownload="true"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import helpers from "@/utils/helpers";
import { find } from "lodash";
import Folders from "./Folders";
import VueTagsInput from "@johmun/vue-tags-input";
import Swal from "sweetalert2";

export default {
  name: "documents",
  components: {
    Folders,
    VueTagsInput,
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin,
      folders: (state) => state.folders,
      userInfo: (state) => state.auth.userInfo,
    }),
    dropzoneOptions: function () {
      return {
        url: `documents/folders/${this.selectedFolderId}/add?owner_id=${this.userInfo.id}`,
      };
    },
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) != -1;
      });
    },
    filteredItemsForFolders() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.folderTag.toLowerCase()) != -1;
      });
    },
  },
  async mounted() {
    try {
      let users = [];
      const usersRes = await this.$http.get("all-users-info");
      usersRes.data.data.forEach((u) => {
        u = { text: u.name, value: u.id, id: u.id };
        users.push(u);
      });
      this.autocompleteItems = users;
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
    }
  },
  data() {
    return {
      documents: {
        isLoading: false,
        data: [],
      },
      selectedDoc: {
        type: "",
        link: "",
        name: "",
      },
      directDocumentShare: false,
      directFolderShare: false,
      selectedFolderId: null,
      tag: "",
      folderTag: "",
      tags: [],
      folderTags: [],
      selectAll: false,
      selectedFileIds: [],
      autocompleteItems: [],
    };
  },
  methods: {
    ...mapActions({
      get: "folders/get",
      download: "folders/download",
      view: "folders/view",
      share: "folders/shareFiles",
      folderShare: "folders/shareFolders",
      deleteDocument: "folders/deleteDocument",
    }),
    uploadSuccess: function () {
      this.get(this.userInfo.id).then(() => {
        this.onSelectedFolderChanged(this.selectedFolderId);
      });
    },
    downloadFile: function (doc) {
      if (doc && !doc.isDownloading) {
        doc.isDownloading = true;
        this.$forceUpdate();
        doc.owner_id = this.userInfo.id;
        this.download(doc).then(() => {
          doc.isDownloading = false;
          this.$forceUpdate();
        });
      }
    },
    getFileIcon: function (doc) {
      if (
        doc.type == "times" ||
        doc.type == "code" ||
        doc.original_file_name.indexOf(".ppt") > -1
      ) {
        if (doc.original_file_name.indexOf(".xls") > -1) {
          return "excel";
        } else if (doc.original_file_name.indexOf(".ppt") > -1) {
          return "powerpoint";
        } else if (doc.original_file_name.indexOf(".doc") > -1) {
          return "word";
        }
      }
      return doc.type;
    },
    canViewFile: function (doc) {
      const allowedfiles = ["image", "pdf", "excel", "word", "video"];
      return allowedfiles.includes(doc.type);
    },
    viewFile: function (doc) {
      if (doc && !doc.isViewing) {
        doc.isViewing = true;
        this.$forceUpdate();
        doc.owner_id = this.userInfo.id;
        this.view(doc).then((link) => {
          doc.isViewing = false;
          this.$forceUpdate();
          if (link) {
            this.selectedDoc = {
              name: doc.original_file_name,
              type: doc.type,
              link,
            };
            if (doc.type === "video") {
              this.$modal.show("videoViewer");
            } else {
              this.$modal.show("file-viewer-modal");
            }
          }
        });
      }
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
    onSelectedFolderChanged: function (event) {
      this.selectedFolderId = event;
      const selectedFolder = find(this.folders.data, {
        id: this.selectedFolderId,
      });
      if (selectedFolder) {
        this.documents.data = selectedFolder.documents || [];
      }
    },
    onSelectedFolderDeleted: function () {
      this.selectedFolderId = null;
      this.documents.data = [];
    },
    shareFiles: function () {
      const userIds = [];
      this.tags.forEach((el) => {
        userIds.push(el.id);
      });
      this.share({
        users_id: `[${userIds}]`,
        file_ids: `[${this.selectedFileIds}]`,
        directShare: this.directDocumentShare,
      });
    },
    shareFolders: function () {
      const userIds = [];
      this.folderTags.forEach((el) => {
        userIds.push(el.id);
      });
      const foldersId = [];
      foldersId.push(this.selectedFolderId);
      this.folderShare({
        users_id: `[${userIds}]`,
        folders_id: `[${foldersId}]`,
        directShare: this.directFolderShare,
      });
    },
    setSelectAll: function (value) {
      const selected = [];
      if (value.target.checked) {
        this.documents.data.forEach(function (document) {
          selected.push(document.id);
        });
      }
      this.selectAll = selected.length == this.documents.data.length;
      this.selectedFileIds = selected;
    },
    deleteDocumentModal(doc) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            doc.isDeleting = true;
            vm.$forceUpdate();
            vm.deleteDocument(doc.id).then((deleted) => {
              doc.isDeleting = false;
              vm.$forceUpdate();
              vm.uploadSuccess();
              if (deleted) {
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your Document has been deleted.",
                  "success"
                );
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.dropzone-multiple .dz-message {
  z-index: 9;
}

@media (max-width: 1024px) {
  .share-folder-wrapper,
  .share-document-wrapper {
    display: block !important;

    .w-75 {
      width: 100% !important;
      margin-bottom: 8px;
    }
  }
  .direct-share-input {
    justify-content: flex-start !important;
    margin-right: 0;
    margin-left: 23px;
  }
  .documents-details-table {
    td,
    th {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }
}
</style>
